/* eslint-disable import/no-anonymous-default-export */
export default {
    openGraph: {
        url: '/',
        title: 'Demiplane',
        description:
            'Find Your Next Adventure On Demiplane - Matchmaking, Video, Voice, Shared Journaling, And Much More To Come!',
        type: 'website',
        images: [
            {
                url: 'https://demiplane-static.s3-us-west-2.amazonaws.com/DemiplaneOG.jpg',
                alt: 'Demiplane',
            },
        ],
        site_name: 'Demiplane',
        siteName: 'Demiplane',
    },
    twitter: {
        handle: '@DemiplaneRPG',
        cardType: 'summary_large_image',
    },
};
