import * as Types from '../../graphql/types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GlobalSearchBarNexusesWithActiveProductsQueryVariables = Types.Exact<{
  nexusSlugs: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type GlobalSearchBarNexusesWithActiveProductsQuery = { __typename?: 'query_root', demiplane_product: Array<{ __typename?: 'demiplane_product', nexus_slug?: string | null }> };


export const GlobalSearchBarNexusesWithActiveProductsDocument = gql`
    query globalSearchBarNexusesWithActiveProducts($nexusSlugs: [String!]!) {
  demiplane_product(
    where: {nexus_slug: {_in: $nexusSlugs}, active: {_eq: true}, product_category: {active: {_eq: true}}}
    distinct_on: nexus_slug
  ) {
    nexus_slug
  }
}
    `;

export function useGlobalSearchBarNexusesWithActiveProductsQuery(options: Omit<Urql.UseQueryArgs<GlobalSearchBarNexusesWithActiveProductsQueryVariables>, 'query'>) {
  return Urql.useQuery<GlobalSearchBarNexusesWithActiveProductsQuery, GlobalSearchBarNexusesWithActiveProductsQueryVariables>({ query: GlobalSearchBarNexusesWithActiveProductsDocument, ...options });
};