import { createContext } from 'react';

interface IRecoilRootContext {
  resetRecoil(): void;
}

const initialState: IRecoilRootContext = {
  resetRecoil: () => {},
};

export const RecoilRootContext =
  createContext<IRecoilRootContext>(initialState);
