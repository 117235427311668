import { gql } from 'urql';

export const SERVER_MUTATION_LEAVE_RECRUIT = gql`
  mutation LeaveRecruit($connectionId: String!) {
    leaveRecruit(connectionId: $connectionId) {
      __typename
      id
    }
  }
`;
