import { createContext } from 'react';
import { Subscription } from 'rxjs';

interface IRxJsEmitterContext {
  initSubject(name: string): void;
  emit<T>(name: string, data: T | undefined): void;
  listen<T>(name: string, handler: (data: T | undefined) => void): Subscription;
  // close(name: string): void;
  dispose(): void;
}

const initialState: IRxJsEmitterContext = {
  initSubject: () => {},
  emit: () => {},
  listen: () => new Subscription(),
  // close: (name: string) => {},
  dispose: () => {},
};

export const RxJsEmitterContext =
  createContext<IRxJsEmitterContext>(initialState);
