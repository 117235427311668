import { withUrqlClient, WithUrqlClientOptions } from 'next-urql';
import { NextPage } from 'next';
import { mergeExchanges } from '../lib/mergeExchanges';

export const withDemiplaneUrqlClient = <P extends {}>(
  Child: NextPage<P>,
  options?: WithUrqlClientOptions
) => {
  return withUrqlClient(
    (ssrExchange, ctx) => {
      return {
        url: `${process.env.NEXT_PUBLIC_API_HTTP_ENDPOINT}`,
        exchanges: mergeExchanges(ctx, ssrExchange),
      };
    },
    !!options ? options : { ssr: false }
  )(Child);
};
