import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import BagIcon from '@components/icons/Bag';
import { useClient } from 'urql';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';
import { UserProvider, useUser } from '@auth0/nextjs-auth0/client';
import useLogout from '@framework/auth/use-logout';
import useCustomer from '@framework/customer/use-customer';
import type { LineItem } from '@commerce/types/cart';
import useCart from '@framework/cart/use-cart';
import { generateToken } from 'src/lib/generateToken';
import { StyledList, StyledSpan } from './CartIconButton.styles';
import { DemiplaneAuthUser } from 'src/lib/serverSidePropsAuthCheck';
import { dynamicImport } from 'src/lib/dynamicImport';

// this is on purpose.  The cart icon is part of top nav and thus on every page.
// CommerceProvider forces the pulling of swell-js which is large and only runs client side anyway.
// Its not needed at page load
const CommerceProvider = dynamicImport(
  'CommerceProvider',
  () => import('@framework').then((mod) => mod.CommerceProvider),
  { ssr: false }
);

interface CartIconButtonProps {
  currentUser?: DemiplaneAuthUser | null;
  isCommerceEnabled: boolean;
}

const countItem = (count: number, item: LineItem) => count + item.quantity;

const CartIconButtonBase = ({
  currentUser,
  isCommerceEnabled,
}: CartIconButtonProps) => {
  const client = useClient();
  const { emit } = useRxJsEmitter();
  const { user } = useUser();
  const logout = useLogout();
  const { data: cartData, mutate: cartMutate } = useCart();
  const { mutate: customerMutate, data: customerData } = useCustomer();
  const [isAuthError, setIsAuthError] = useState(false);
  const [isCommerceLoggedIn, setIsCommerceLoggedIn] = useState(false);
  const itemsCount = cartData?.lineItems.reduce(countItem, 0) ?? 0;

  useEffect(() => {
    const loginCommerce = async () => {
      if (isCommerceEnabled) {
        if (!!user && !!currentUser && !isCommerceLoggedIn) {
          try {
            if (process.env.NEXT_PUBLIC_COMMERCE_PROVIDER === 'swell') {
              let generatedToken: boolean;
              setIsCommerceLoggedIn(true);
              generatedToken = await generateToken(client);

              if (!generatedToken) {
                if (!isAuthError) {
                  console.log(
                    `Error: generatedToken() returned false. currentUser = ${
                      currentUser.id
                    }; userAuthenticated = ${!!user}; customerData = ${customerData};`
                  );
                }
                setIsAuthError(true);
                logout();
                return;
              }

              const swell = await import('swell-js');

              await swell.cart.update({
                shipping: {
                  name: [currentUser.first_name, currentUser.last_name].join(
                    ' '
                  ),
                  address1: currentUser.address1,
                  address2: currentUser.address2,
                  city: currentUser.city,
                  state: currentUser.region.code2,
                  zip: currentUser.zipcode,
                  country: currentUser.country.code2,
                },
                billing: {
                  name: [currentUser.first_name, currentUser.last_name].join(
                    ' '
                  ),
                  address1: currentUser.address1,
                  address2: currentUser.address2,
                  city: currentUser.city,
                  state: currentUser.region.code2,
                  zip: currentUser.zipcode,
                  country: currentUser.country.code2,
                },
              });

              if (!!customerMutate) {
                await customerMutate();
              }

              if (!!cartMutate) {
                await cartMutate();
              }
            } else if (
              process.env.NEXT_PUBLIC_COMMERCE_PROVIDER !== 'bigcommerce'
            ) {
              throw new Error('NEXT_PUBLIC_COMMERCE_PROVIDER unknown');
            }
          } catch (exception) {
            setIsAuthError(true);
            console.log(exception);
            try {
              logout();
            } catch (e) {
              console.log('error logging out of commerce', e);
            }
          }
          return;
        }
      }
    };
    loginCommerce();
  }, [
    cartMutate,
    client,
    currentUser,
    customerData,
    customerMutate,
    isAuthError,
    isCommerceEnabled,
    isCommerceLoggedIn,
    logout,
    user,
  ]);

  const handleOpenNewPortalDrawer = () => {
    emit('openCartDrawer', {});
  };

  return (
    <>
      {isCommerceEnabled && !isAuthError && (
        <ul
          style={{
            marginTop: '0px',
            marginLeft: '0px',
            marginBottom: '0px',
            paddingLeft: '0px',
          }}
        >
          <StyledList>
            <IconButton
              aria-label='Cart'
              onClick={handleOpenNewPortalDrawer}
              size='large'
              data-cy='header-cart-btn'
              sx={{
                fill: '#fff',
                display: 'block',
                mx: 0.5,
                pl: { xs: 0, sm: 1.5 },
              }}
            >
              <BagIcon
                sx={{
                  width: { xs: 24 },
                  height: { xs: 24 },
                }}
              />

              {itemsCount > 0 && (
                <StyledSpan
                  data-cy='header-cart-count-badge'
                  sx={{
                    left: { xs: 12, sm: '1.75rem' },
                  }}
                >
                  {itemsCount}
                </StyledSpan>
              )}
            </IconButton>
          </StyledList>
        </ul>
      )}
    </>
  );
};

export const CartIconButton = (props: CartIconButtonProps) => (
  <UserProvider>
    <CommerceProvider locale={'en-US'}>
      <CartIconButtonBase {...props} />
    </CommerceProvider>
  </UserProvider>
);
