import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  wrapperContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  inputContainer: {
    // margin: 'auto',
    width: '100%',
  },
  searchIcon: {
    color: '#676767',
    cursor: 'default',
  },
  searchInput: {
    color: '#000000',
    display: 'flex',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '0.75rem',
  },
  nexusContainer: {
    // margin: 'auto',
    // width: '6.75rem',
  },
  nexusSelect: {
    color: theme.palette.common.black,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    maxWidth: '6rem',
    minWidth: '6rem',
    overflow: 'hidden',
    whitespace: 'nowrap',
    textAlign: 'right',
  },
  menuList: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.common.black,
  },
  selectPaper: {
    // hack to fix Material UI popover position when disablePortal is true
    left: `${theme.spacing(2)}px !important`,
    maxHeight: 'none !important',
    top: `${theme.spacing(2)}px !important`,
    backgroundColor: theme.palette.common.white,
  },
}));
