import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SlsReadUnreadNotificationsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SlsReadUnreadNotificationsMutation = { __typename?: 'mutation_root', slsReadUnreadNotifications?: { __typename?: 'BaseResponse', data?: { [key: string]: any } | null, error?: string | null, message?: string | null, success: boolean } | null };


export const SlsReadUnreadNotificationsDocument = gql`
    mutation slsReadUnreadNotifications {
  slsReadUnreadNotifications {
    data
    error
    message
    success
  }
}
    `;

export function useSlsReadUnreadNotificationsMutation() {
  return Urql.useMutation<SlsReadUnreadNotificationsMutation, SlsReadUnreadNotificationsMutationVariables>(SlsReadUnreadNotificationsDocument);
};