import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IsUserOnlineQueryVariables = Types.Exact<{
  userId: Types.Scalars['bigint']['input'];
}>;


export type IsUserOnlineQuery = { __typename?: 'query_root', isUserOnline?: { __typename?: 'IsUserOnlineOutput', message: string, result: boolean, success: boolean } | null };


export const IsUserOnlineDocument = gql`
    query isUserOnline($userId: bigint!) {
  isUserOnline(userId: $userId) {
    message
    result
    success
  }
}
    `;

export function useIsUserOnlineQuery(options: Omit<Urql.UseQueryArgs<IsUserOnlineQueryVariables>, 'query'>) {
  return Urql.useQuery<IsUserOnlineQuery, IsUserOnlineQueryVariables>({ query: IsUserOnlineDocument, ...options });
};