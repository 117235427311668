import React, { useState } from 'react';
import { UserAvatar } from '@demiplane-dev/ui-components/src/Tokens/UserAvatar';
import { useChat } from 'Hooks/Chat';
import { useRouter } from 'next/router';
import { darken, useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { UserProvider, useUser } from '@auth0/nextjs-auth0/client';

import { DemiplaneAuthUser } from 'src/lib/serverSidePropsAuthCheck';
import { useCurrentUser } from 'Hooks/CurrentUser';
import { dynamicImport } from 'src/lib/dynamicImport';

const ProfileDropDownMenu = dynamicImport(
  'ProfileDropDownMenu',
  () => import('../ProfileDropDownMenu').then((mod) => mod.ProfileDropDownMenu),
  { ssr: false }
);

interface TopNavAvatarProps {
  currentUserGssp?: DemiplaneAuthUser | null;
}

const TopNavAvatarBase = ({ currentUserGssp }: TopNavAvatarProps) => {
  const theme = useTheme();
  const { user } = useUser();
  const { currentUser: currentUserRecoil } = useCurrentUser();
  const router = useRouter();
  const { generateAvatarColorHash } = useChat();
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleLoginRedirect = () => {
    if (!!user) {
      router.push(`/api/auth/logout?returnTo=${router.asPath}?login=true`);
    } else {
      window.dataLayer?.push({ event: 'signup_signin' });
      router.push(`/api/auth/login?returnTo=${router.asPath}?login=true`);
    }
  };

  const currentUser =
    currentUserRecoil?.upload?.file !== currentUserGssp?.upload?.file
      ? currentUserRecoil
      : currentUserGssp;

  return (
    <>
      {!!currentUser ? (
        <>
          <UserAvatar
            height='40px'
            width='40px'
            id={currentUser.id}
            name={currentUser.username}
            backgroundColor={generateAvatarColorHash(currentUser.username)}
            handleClick={handleAvatarClick}
            src={currentUser.upload?.file}
            displayPointer
          />
          {!!profileMenuAnchorEl && (
            <ProfileDropDownMenu
              anchorEl={profileMenuAnchorEl}
              onClose={handleProfileMenuClose}
              currentUser={currentUser}
            />
          )}
        </>
      ) : (
        <Button
          onClick={handleLoginRedirect}
          color='primary'
          sx={{
            width: { xs: 'auto', lg: 156 },
            height: 38,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.primary.main, 0.1),
            },
            color: 'secondary.contrastText',
          }}
          data-cy='sign-in-button'
        >
          <Typography variant='h2' fontWeight={600} noWrap color='common.white'>
            Sign up / Sign in
          </Typography>
        </Button>
      )}
    </>
  );
};

export const TopNavAvatar = (props: TopNavAvatarProps) => (
  <UserProvider>
    <TopNavAvatarBase {...props} />
  </UserProvider>
);
