import { gql } from 'urql';

export const SERVER_MUTATION_UPDATE_FIRST_LOGIN = gql`
  mutation updateFirstLogin($firstLogin: Boolean!) {
    updateFirstLogin(firstLogin: $firstLogin) {
      __typename
      id
      cognitoId
      stripeConnectId
      username
      email
      firstName
      lastName
      dob
      address1
      address2
      city
      zipcode
      country {
        __typename
        id
        code3
        name
      }
      region {
        __typename
        id
        code2
        name
      }
      timeZone
      gameMasterBio
      experience
      combat
      puzzlesLogic
      socialIntrigue
      playerStory
      rolePlaying
      strictRules
      created
      updated
      firstLogin
      accessLevel
      featureLevel
    }
  }
`;
