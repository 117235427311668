import { gql } from 'urql';

export const SERVER_QUERY_SLS_CURRENT_USER = gql`
  query slsCurrentUser {
    slsCurrentUser {
      success
      message
      data
      error
    }
  }
`;
