import {
  Input,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState, useEffect, ReactElement } from 'react';
import { Search } from '@mui/icons-material';
import { useStyles } from './styles';
import { useRouter } from 'next/router';
import { useActiveNexusQuery } from './active-nexus.query.generated';
import { useGlobalSearchBarNexusesWithActiveProductsQuery } from './global-search-bar-products-by-nexus-slug.query.generated';
import { NexusBySlugQuery } from 'Graphql/nexus-by-slug.query.generated';

interface IGlobalSearchBarProps {
  inHeader: boolean;
  nexus?: NexusBySlugQuery | undefined;
  onSubmit?: () => void;
  searchClicked?: boolean;
  width?: string | number;
}

export const GlobalSearchBar = ({
  nexus,
  inHeader,
  onSubmit,
  searchClicked,
  width,
}: IGlobalSearchBarProps) => {
  const router = useRouter();
  const classes = useStyles({ inHeader });
  const { nexusSlug, term } = router.query;
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [searchSlug, setSearchSlug] = useState('all');
  const [nexusSearchName, setNexusSearchName] = useState('All');
  const [updatedNexusSlug, setUpdatedNexusSlug] = useState(nexusSlug);
  const [menuElements, setMenuElements] = useState<ReactElement[]>([]);
  const [nexusSlugs, setNexusSlugs] = useState<string[] | undefined>();
  const [referringNexus, setReferringNexus] = useState<string | undefined>(
    nexusSlug as string
  );

  const [nexusesResult] = useActiveNexusQuery();
  const { data: nexusesData, fetching: nexusesLoading } = nexusesResult;

  const [productsResult] = useGlobalSearchBarNexusesWithActiveProductsQuery({
    variables: {
      nexusSlugs: nexusSlugs ?? [],
    },
    pause: !nexusSlugs,
    requestPolicy: 'cache-and-network',
  });

  const { data: productsData, fetching: productsLoading } = productsResult;

  useEffect(() => {
    let isMounted = true;

    if (!!nexus?.demiplane_nexus && nexus.demiplane_nexus.length > 0) {
      if (isMounted) {
        setReferringNexus(nexus.demiplane_nexus[0].slug);
      }
    }
  }, [nexus?.demiplane_nexus]);

  useEffect(() => {
    let isMounted = true;

    if (!!nexusSlug && isMounted) {
      setUpdatedNexusSlug(nexusSlug as string);
    }

    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  }, [nexusSlug]);

  useEffect(() => {
    let isMounted = true;

    if (
      !!nexusesData?.demiplane_nexus &&
      nexusesData.demiplane_nexus.length > 0
    ) {
      if (isMounted) {
        setNexusSlugs(nexusesData.demiplane_nexus.map((nexus) => nexus.slug));
      }
    }
    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  }, [nexusesData]);

  useEffect(() => {
    let isMounted = true;

    const setupMenuItems = async () => {
      if (!!term && isMounted) {
        setSearchTerm(term as string);
      }

      if (
        !!nexusesData &&
        !nexusesLoading &&
        !!nexusesData.demiplane_nexus &&
        nexusesData.demiplane_nexus.length >= 1 &&
        !productsLoading &&
        !!productsData?.demiplane_product &&
        productsData.demiplane_product.length >= 1
      ) {
        let updatedNexusSlug = 'all';
        let updatedNexusSearchName = 'All';

        // Determine if we're in a nexus
        nexusesData.demiplane_nexus.forEach((n) => {
          if (!!nexusSlug) {
            if (nexusSlug === n.slug) {
              updatedNexusSlug = n.slug;
              if (n.is_publisher) {
                updatedNexusSearchName = 'All';
              } else {
                updatedNexusSearchName = n.name;
              }
            }
          }
        });

        const menuElements: ReactElement[] = [];
        nexusesData.demiplane_nexus.forEach((n) => {
          const nexusesWithActiveProducts = productsData.demiplane_product.map(
            (p) => p.nexus_slug
          );
          if (nexusesWithActiveProducts.includes(n.slug)) {
            // Build list of select menu items
            menuElements.push(
              <MenuItem
                key={n.slug}
                className={classes.menuList}
                value={n.name}
                selected={updatedNexusSearchName === n.name ? true : false}
              >
                {n.name}
              </MenuItem>
            );
          }
        });

        if (isMounted) {
          setMenuElements(menuElements);
          setSearchSlug(updatedNexusSlug);
          setNexusSearchName(updatedNexusSearchName);
        }
      }
    };
    setupMenuItems();

    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  }, [
    nexusesData,
    nexusesLoading,
    term,
    classes.menuList,
    nexusSlug,
    productsData,
    productsLoading,
  ]);

  return (
    <Paper sx={{ width, backgroundColor: 'common.white', height: 38 }}>
      <div className={classes.center}>
        <div
          id='global-search-wrapper-container'
          className={classes.wrapperContainer}
        >
          <div
            id='global-search-icon-container'
            className={classes.iconContainer}
          >
            <Search classes={{ root: classes.searchIcon }} />
          </div>
          <div
            id='global-search-input-container'
            className={classes.inputContainer}
          >
            <Input
              autoFocus={searchClicked}
              value={searchTerm}
              placeholder='Search'
              classes={{ root: classes.searchInput }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTerm(event.target.value);
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                const code = event.key;
                if (
                  !!code &&
                  code === 'Enter' &&
                  !!searchTerm &&
                  searchTerm.length > 0
                ) {
                  if (!!onSubmit) {
                    onSubmit();
                  }
                  if (nexusSearchName.match('All')) {
                    router.push(`/search-results?term=${searchTerm}&nexus=all`);
                  } else {
                    const referringNexusQueryParam: string | undefined =
                      !!referringNexus
                        ? `&referringNexus=${referringNexus}`
                        : undefined;

                    router.push(
                      `/nexus/${updatedNexusSlug}/search-results?term=${searchTerm}&nexus=${searchSlug}${
                        referringNexusQueryParam ?? ''
                      }`
                    );
                  }
                }
              }}
              disableUnderline={true}
            />
          </div>
          <div
            id='global-search-nexus-container'
            className={classes.nexusContainer}
          >
            <Select
              value={`${nexusSearchName}`}
              className={classes.nexusSelect}
              variant={'standard'}
              input={
                <Input disableUnderline={true} style={{ paddingTop: '6px' }} />
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                classes: { paper: classes.selectPaper },
                // disablePortal: true,
              }}
              sx={{ '& .MuiSelect-icon': { color: 'demi.blue' } }}
              onChange={(event: SelectChangeEvent<string>) => {
                if (!!event.target.value) {
                  event.preventDefault();
                  nexusesData?.demiplane_nexus.forEach((n) => {
                    if (event.target.value === n.name) {
                      setSearchSlug(n.slug);
                      setNexusSearchName(n.name);
                      setUpdatedNexusSlug(n.slug);
                    } else if (event.target.value === 'All') {
                      setSearchSlug('all');
                      setNexusSearchName('All');
                    }
                  });
                }
              }}
            >
              <MenuItem
                className={classes.menuList}
                value='All'
                selected={nexusSearchName === 'All' ? true : false}
              >
                All
              </MenuItem>
              {menuElements}
            </Select>
          </div>
        </div>
      </div>
    </Paper>
  );
};
