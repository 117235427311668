import React, { useCallback, useMemo } from 'react';
import { ChatContext } from '../contexts/ChatContext';
import hash from 'object-hash';
import { TConversation, IConversationParticipants } from '@demiplane-dev/types';
import {
  SERVER_QUERY_SLS_CHAT_TOKEN,
  SERVER_QUERY_SLS_USER_CONVERSATIONS,
} from '../../gql/server';
import { OperationResult, useClient } from 'urql';
import {
  IsUserOnlineQuery,
  IsUserOnlineDocument,
} from 'src/graphql/is-user-online.query.generated';
import { DemiplaneUser } from 'src/graphql/types';
import {
  UnreadMessageCountByConversationQuery,
  UnreadMessageCountByConversationDocument,
} from 'src/graphql/unread-message-count-by-conversation.generated';
import {
  SlsConversationParticipantsDocument,
  SlsConversationParticipantsQuery,
} from 'Graphql/sls-conversation-participants.query.generated';
import { useSlsSubmitMessageMutation } from 'Graphql/sls-submit-message.mutation.generated';
import { useSlsSubmitSystemMessageMutation } from 'Graphql/sls-submit-system-message.mutation.generated';
import { useSlsSubmitGiphyMutation } from 'Graphql/sls-submit-giphy.mutation.generated';

export const ChatProvider = React.memo(({ ...props }) => {
  const urqlClient = useClient();

  const [, submitMessageMutation] = useSlsSubmitMessageMutation();
  const [, submitSystemMessageMutation] = useSlsSubmitSystemMessageMutation();
  const [, submitGiphyMutation] = useSlsSubmitGiphyMutation();

  const getChatSignature = useCallback(async () => {
    let chatSignature = undefined;
    const result: OperationResult = await urqlClient
      .query(
        SERVER_QUERY_SLS_CHAT_TOKEN,
        {},
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise();

    if (!!result.error) {
      console.log(result.error);
      return undefined;
    }

    if (!!result.data && result.data.slsChatToken.success) {
      chatSignature = result.data.slsChatToken.data.token;
    }

    return chatSignature;
  }, [urqlClient]);

  const generateAdventureChatHash = useCallback(
    (adventureId: string, type: string) => {
      let hashArray = ['adventure', adventureId, type];

      const sortedHashArray = hashArray.sort();

      return hash(sortedHashArray);
    },
    []
  );

  const generateAdventurePrivateChatHash = useCallback(
    (members: Pick<DemiplaneUser, 'cognito_id'>[], adventureId: string) => {
      const memberIds = members.map((m) => m.cognito_id);

      let hashArray = ['adventure', adventureId, ...memberIds];

      const sortedHashArray = hashArray.sort();

      return hash(sortedHashArray);
    },
    []
  );

  const getUnreadMessageCountByConversation = useCallback(
    async (conversationId: string) => {
      try {
        const result: OperationResult<UnreadMessageCountByConversationQuery> =
          await urqlClient
            .query(
              UnreadMessageCountByConversationDocument,
              { conversationId },
              {
                requestPolicy: 'network-only',
              }
            )
            .toPromise();

        if (!!result.error) {
          console.log(result.error);
          return 0;
        }
        return result.data?.unreadMessageCountByConversation?.result ?? 0;
      } catch (err) {
        console.log(err); // show something on screen
      }
    },
    [urqlClient]
  );

  const getUserConversations = useCallback(async () => {
    let conversations: TConversation[] = [];
    try {
      const result: OperationResult = await urqlClient
        .query(
          SERVER_QUERY_SLS_USER_CONVERSATIONS,
          {},
          {
            requestPolicy: 'network-only',
          }
        )
        .toPromise();

      if (!!result.error) {
        console.log(result.error);
        return [];
      }

      if (
        !!result.data &&
        result.data.slsUserConversations.success &&
        !!result.data.slsUserConversations.data
      ) {
        conversations = result.data.slsUserConversations.data;
      }
    } catch (err) {
      console.log(err); // show something on screen
    }

    return conversations;
  }, [urqlClient]);

  const generateAvatarColorHash = useCallback((str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const c = (hash & 0x00ffffff).toString(16).toUpperCase();

    return '#393636'.substring(0, 7 - c.length) + c;
  }, []);

  const getConversationParticipants = useCallback(
    async (chatHash: string) => {
      let participants: IConversationParticipants | undefined = undefined;

      const result: OperationResult<SlsConversationParticipantsQuery> =
        await urqlClient
          .query(
            SlsConversationParticipantsDocument,
            { chatHash },
            {
              requestPolicy: 'network-only',
            }
          )
          .toPromise();

      if (!!result.error) {
        console.log(result.error);
        return undefined;
      }

      if (!!result.data?.slsConversationParticipants?.data) {
        participants =
          result.data.slsConversationParticipants.data.conversationParticipants;
      }

      return participants;
    },
    [urqlClient]
  );

  const submitMessage = useCallback(
    async (chatHash: string, message: string) => {
      try {
        await submitMessageMutation({
          chatHash,
          message,
        });

        return true;
      } catch (err) {
        console.log(err); // show something on screen
        return false;
      }
    },
    [submitMessageMutation]
  );

  const submitSystemMessage = useCallback(
    async (chatHash: string, message: string) => {
      try {
        await submitSystemMessageMutation({
          chatHash: chatHash,
          message: message,
        });
        return true;
      } catch (err) {
        console.log(err); // show something on screen
        return false;
      }
    },
    [submitSystemMessageMutation]
  );

  const submitGiphy = useCallback(
    async (chatHash: string, url: string) => {
      try {
        await submitGiphyMutation({
          chatHash,
          url,
        });

        return true;
      } catch (err) {
        console.log(err); // show something on screen
        return false;
      }
    },
    [submitGiphyMutation]
  );

  const isUserOnline = useCallback(
    async (userId: string) => {
      try {
        const result: OperationResult<IsUserOnlineQuery> = await urqlClient
          .query(
            IsUserOnlineDocument,
            { userId },
            {
              requestPolicy: 'network-only',
            }
          )
          .toPromise();

        if (!!result.error) {
          console.log(result.error);
          return false;
        }
        return result.data?.isUserOnline?.result ?? false;
      } catch (err) {
        console.log(err);
      }
      return true;
    },
    [urqlClient]
  );

  const returnObj = useMemo(() => {
    return {
      getChatSignature,
      getUnreadMessageCountByConversation,
      generateAvatarColorHash,
      generateAdventureChatHash,
      generateAdventurePrivateChatHash,
      getUserConversations,
      getConversationParticipants,
      submitMessage,
      submitSystemMessage,
      submitGiphy,
      isUserOnline,
    };
  }, [
    generateAdventureChatHash,
    getUnreadMessageCountByConversation,
    generateAdventurePrivateChatHash,
    generateAvatarColorHash,
    getChatSignature,
    getConversationParticipants,
    getUserConversations,
    submitMessage,
    submitSystemMessage,
    submitGiphy,
    isUserOnline,
  ]);

  return <ChatContext.Provider value={returnObj} {...props} />;
});

ChatProvider.displayName = 'ChatProvider';
