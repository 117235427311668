import { gql } from 'urql';

export const SERVER_MUTATION_LEAVE_REQUEST = gql`
  mutation LeaveRequest($connectionId: String!) {
    leaveRequest(connectionId: $connectionId) {
      __typename
      id
    }
  }
`;
