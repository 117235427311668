import { createContext } from 'react';

interface INotificationContext {
  readUnreadNotifications: () => Promise<boolean>;
  readNotification: (notificationId: string) => Promise<boolean>;
  readNotificationByUrl: (url: string) => Promise<boolean>;
}

const initialState: INotificationContext = {
  readUnreadNotifications: () =>
    new Promise<boolean>((resolve) => resolve(false)),
  readNotification: () => new Promise<boolean>((resolve) => resolve(false)),
  readNotificationByUrl: () =>
    new Promise<boolean>((resolve) => resolve(false)),
};

export const NotificationContext =
  createContext<INotificationContext>(initialState);
