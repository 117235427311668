import { gql } from 'urql';

export const SERVER_QUERY_SEARCH_ADS = gql`
  query searchAds(
    $recordCount: Float!
    $gameTypeIds: [Int]!
    $adventuringPlatformIds: [Int]!
    $frequencyOneTime: Boolean!
    $frequencyRecurring: Boolean!
    $availability: [AvailabilityInput]!
    $quickMatch: Boolean!
    $newPlayer: Boolean!
    $noCost: Boolean!
  ) {
    searchAds(
      recordCount: $recordCount
      gameTypeIds: $gameTypeIds
      adventuringPlatformIds: $adventuringPlatformIds
      frequencyOneTime: $frequencyOneTime
      frequencyRecurring: $frequencyRecurring
      availability: $availability
      quickMatch: $quickMatch
      newPlayer: $newPlayer
      noCost: $noCost
    ) {
      id
      name
      matchScore
      acceptedAdventurerCount
      openAdventurerCount
      requestedAdventurerCount
      gameTypeName
      adventuringPlatformName
      frequency
      dowSunday
      dowMonday
      dowTuesday
      dowWednesday
      dowThursday
      dowFriday
      dowSaturday
      quickMatch
      quickMatchDate
      cost
      image
    }
  }
`;
