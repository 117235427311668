import dynamic, { DynamicOptions, Loader } from 'next/dynamic';

export const dynamicImport = <P = {}>(
  displayName: string,
  dynamicOptions: DynamicOptions<P> | Loader<P>,
  options?: DynamicOptions<P> | undefined
) => {
  const DynamicComponent = dynamic(dynamicOptions, { ...options });
  DynamicComponent.displayName = displayName;

  return DynamicComponent;
};
