import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    appContainer: () => {
      return {
        height: '100dvh',
        background: theme.palette.background.paper,
      };
    },
  };
});
