import React, { useRef } from 'react';
import { RxJsEmitterContext } from '../contexts/RxJsEmitterContext';
import { Subject } from 'rxjs';

const hasOwnProp = {}.hasOwnProperty;
const createName = (name: string) => {
  return `$ ${name}`;
};

const RxJsEmitterProviderComp = ({ ...props }) => {
  const _subjects = useRef<{
    [key: string]: Subject<any | undefined>;
  }>({});

  const initSubject = <T,>(name: string) => {
    const fnName = createName(name);

    if (!_subjects.current[fnName]) {
      _subjects.current[fnName] = new Subject<T | undefined>();
    }
  };

  const emit = <T,>(name: string, data: T | undefined) => {
    const fnName = createName(name);

    if (!_subjects.current[fnName]) {
      _subjects.current[fnName] = new Subject<T | undefined>();
    }

    _subjects.current[fnName].next(data);
  };

  const listen = <T,>(name: string, handler: (data: T | undefined) => void) => {
    const fnName = createName(name);

    if (!_subjects.current[fnName]) {
      _subjects.current[fnName] = new Subject<T | undefined>();
    }

    return _subjects.current[fnName].subscribe(handler);
  };

  // const close = (name: string) => {
  //   const fnName = createName(name);

  //   const test = _subjects.current[fnName];

  //   if (!_subjects.current[fnName]) {
  //     _subjects.current[fnName] = new Subject();
  //   }

  //   return _subjects.current[fnName].unsubscribe();
  // };

  const dispose = () => {
    const subjects = _subjects.current;
    for (let prop in subjects) {
      if (hasOwnProp.call(subjects, prop)) {
        subjects[prop].complete();
      }
    }

    _subjects.current = {};
  };

  return (
    <RxJsEmitterContext.Provider value={{ initSubject, emit, listen, dispose }}>
      {props.children}
    </RxJsEmitterContext.Provider>
  );
};

export const RxJsEmitterProvider = RxJsEmitterProviderComp;
