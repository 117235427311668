import { Badge, IconButton } from '@mui/material';
import { BellIcon } from 'Icons/BellIcon';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';
import { NotificationNotificationHistoryFieldsFragment } from 'Components/NotificationDrawer/notification-notification-history.fragment.generated';

type NotificationsIconButtonProps = {
  hasUnread: boolean;
  unreadNotifications: NotificationNotificationHistoryFieldsFragment[];
  readNotifications: NotificationNotificationHistoryFieldsFragment[];
};

export const NotificationsIconButton = ({
  hasUnread,
  unreadNotifications,
  readNotifications,
}: NotificationsIconButtonProps) => {
  const { emit } = useRxJsEmitter();

  const toggleNotificationDrawer = () => {
    emit('openNotificationDrawer', {
      unreadNotifications,
      readNotifications,
    });
  };

  let bellIcon = (
    <IconButton
      aria-label='notifications'
      sx={{
        color: 'secondary.contrastText',
        display: { xs: 'none', sm: 'block' },
        ml: 0.5,
        mr: 1,
      }}
      size='large'
      data-cy='top-nav-notifications-btn'
      onClick={toggleNotificationDrawer}
    >
      <BellIcon height='21' width='21' />
    </IconButton>
  );

  if (hasUnread) {
    bellIcon = (
      <Badge
        variant='dot'
        badgeContent={''}
        color='primary'
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiBadge-colorPrimary': {
            backgroundColor: 'demi.blue',
            top: 10,
            right: 20,
          },
        }}
        data-cy='top-nav-notifications-badge'
      >
        <IconButton
          edge='end'
          aria-label='notifications'
          onClick={toggleNotificationDrawer}
          sx={{
            color: 'secondary.contrastText',
            display: { xs: 'none', sm: 'block' },
            ml: 0.5,
            mr: 1,
          }}
          size='large'
          data-cy='top-nav-notifications-btn'
        >
          <BellIcon height='21' width='21' />
        </IconButton>
      </Badge>
    );
  }
  return <>{bellIcon}</>;
};
