import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SlsReadNotificationByUrlMutationVariables = Types.Exact<{
  url: Types.Scalars['String']['input'];
}>;


export type SlsReadNotificationByUrlMutation = { __typename?: 'mutation_root', slsReadNotificationByUrl?: { __typename?: 'BaseResponse', data?: { [key: string]: any } | null, error?: string | null, message?: string | null, success: boolean } | null };


export const SlsReadNotificationByUrlDocument = gql`
    mutation slsReadNotificationByUrl($url: String!) {
  slsReadNotificationByUrl(url: $url) {
    data
    error
    message
    success
  }
}
    `;

export function useSlsReadNotificationByUrlMutation() {
  return Urql.useMutation<SlsReadNotificationByUrlMutation, SlsReadNotificationByUrlMutationVariables>(SlsReadNotificationByUrlDocument);
};