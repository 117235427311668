import { gql } from 'urql';

export const SERVER_MUTATION_ADD_CREDIT_CARD = gql`
  mutation AddCreditCard(
    $cardType: String!
    $expirationMonth: Float!
    $expirationYear: Float!
    $lastDigits: String!
    $stripeToken: String!
    $stripeCardId: String!
  ) {
    addCreditCard(
      creditCard: {
        cardType: $cardType
        expirationMonth: $expirationMonth
        expirationYear: $expirationYear
        lastDigits: $lastDigits
        stripeToken: $stripeToken
        stripeCardId: $stripeCardId
      }
    ) {
      id
      cardType
      expirationMonth
      expirationYear
      lastDigits
      stripeToken
      stripeCardId
    }
  }
`;
