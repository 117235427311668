import { createContext } from 'react';
import { IConversationParticipants, TConversation } from '@demiplane-dev/types';
import { DemiplaneUser } from 'src/graphql/types';

interface IChatContext {
  getChatSignature(): Promise<string | undefined>;
  getUnreadMessageCountByConversation(
    conversationId: string
  ): Promise<number | undefined>;
  generateAvatarColorHash(str: string): string;
  generateAdventureChatHash(adventureId: string, type: string): string;
  generateAdventurePrivateChatHash(
    members: Pick<DemiplaneUser, 'cognito_id'>[],
    adventureId: string
  ): string;
  getUserConversations(): Promise<TConversation[]>;
  getConversationParticipants(
    chatHash: string
  ): Promise<IConversationParticipants | undefined>;
  submitMessage: (chatHash: string, message: string) => Promise<boolean>;
  submitSystemMessage: (chatHash: string, message: string) => Promise<boolean>;
  submitGiphy: (chatHash: string, url: string) => Promise<boolean>;
  isUserOnline: (userId: string) => Promise<boolean>;
}

const initialState: IChatContext = {
  getChatSignature: () =>
    new Promise<string | undefined>((resolve) => resolve(undefined)),
  getUnreadMessageCountByConversation: () =>
    new Promise<number | undefined>((resolve) => resolve(undefined)),
  generateAvatarColorHash: () => '',
  generateAdventureChatHash: () => '',
  generateAdventurePrivateChatHash: () => '',
  getUserConversations: () =>
    new Promise<TConversation[]>((resolve) => resolve([])),
  getConversationParticipants: () =>
    new Promise<IConversationParticipants | undefined>((resolve) =>
      resolve(undefined)
    ),
  submitMessage: () => new Promise<boolean>((resolve) => resolve(false)),
  submitSystemMessage: () => new Promise<boolean>((resolve) => resolve(false)),
  submitGiphy: () => new Promise<boolean>((resolve) => resolve(false)),
  isUserOnline: () => new Promise<boolean>((resolve) => resolve(false)),
};

const ChatContextComp = createContext<IChatContext>(initialState);

export const ChatContext = ChatContextComp;
