import { gql } from 'urql';

export const SERVER_MUTATION_UPDATE_LEADER_HAS_VIEWED = gql`
  mutation UpdateLeaderHasViewed(
    $adventureId: String!
    $isGm: Boolean!
    $isAdventurer: Boolean!
  ) {
    updateLeaderHasViewed(
      adventureId: $adventureId
      isGm: $isGm
      isAdventurer: $isAdventurer
    ) {
      __typename
      id
      name
      defaultAdventure
      hasLeaderViewed
      demicard {
        __typename
        id
        file
      }
      adventurePlayers {
        id
        user {
          id
          cognitoId
          username
          firstName
          lastName
          userAdventureRoles {
            role {
              __typename
              id
              roleName
            }
          }
        }
        isGm
        playerName
      }
      connections {
        __typename
        id
        contactType {
          __typename
          id
          name
        }
        contactTypeDate
        connectionType {
          __typename
          id
          name
        }
        connectionTypeDate
        connectionStatus {
          __typename
          id
          name
        }
        connectionStatusDate
        requestor {
          __typename
          id
          username
          cognitoId
        }
        requestorApproval
        requestee {
          __typename
          id
          username
          cognitoId
        }
        requesteeEmail
        requesteeApproval
      }
    }
  }
`;
