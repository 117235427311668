import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SlsSubmitMessageMutationVariables = Types.Exact<{
  chatHash: Types.Scalars['String']['input'];
  message: Types.Scalars['String']['input'];
}>;


export type SlsSubmitMessageMutation = { __typename?: 'mutation_root', slsSubmitMessage?: { __typename?: 'BaseResponse', data?: { [key: string]: any } | null, error?: string | null, message?: string | null, success: boolean } | null };


export const SlsSubmitMessageDocument = gql`
    mutation slsSubmitMessage($chatHash: String!, $message: String!) {
  slsSubmitMessage(chatHash: $chatHash, message: $message) {
    data
    error
    message
    success
  }
}
    `;

export function useSlsSubmitMessageMutation() {
  return Urql.useMutation<SlsSubmitMessageMutation, SlsSubmitMessageMutationVariables>(SlsSubmitMessageDocument);
};