import * as Types from '../../../../graphql/types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GenerateTokenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateTokenQuery = { __typename?: 'query_root', generateToken?: { __typename?: 'GenerateTokenOutput', message: string, result?: { [key: string]: any } | null, success: boolean } | null };


export const GenerateTokenDocument = gql`
    query generateToken {
  generateToken {
    message
    result
    success
  }
}
    `;

export function useGenerateTokenQuery(options?: Omit<Urql.UseQueryArgs<GenerateTokenQueryVariables>, 'query'>) {
  return Urql.useQuery<GenerateTokenQuery, GenerateTokenQueryVariables>({ query: GenerateTokenDocument, ...options });
};