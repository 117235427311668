import { createClient as createWSClient } from 'graphql-ws';
import {
  cacheExchange,
  dedupExchange,
  Operation,
  subscriptionExchange,
} from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import WebSocket from 'isomorphic-ws';
import { isTokenValid } from './token';

interface IAuthState {
  token?: string;
  refreshToken: string;
  decodedToken?: { [key: string]: any };
}

interface IGetAuth {
  authState: IAuthState;
  mutate: any;
}

type WillAuthErrorInput = {
  operation: Operation<any, any>;
  authState: IAuthState | null;
};

export const mergeExchanges = (ctx: any, ssr: any) => {
  const wsClient = createWSClient({
    url: `${process.env.NEXT_PUBLIC_API_WS_ENDPOINT}`,
    lazy: true,
    connectionParams: async () => {
      let token: string | undefined;
      let session: any = undefined;
      const sessionResponse = await fetch(
        `${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}/api/auth/fetch-session`
      );
      if (sessionResponse?.ok) {
        session = await sessionResponse.json();
      }
      if (
        !!session &&
        (!session.errorCode || session.errorCode !== 'NOT_AUTHORIZED')
      ) {
        token = session.accessToken;

        const { decodeJwt } = await import('jose');

        const decodedToken = decodeJwt(token || '');

        if (!!token && !isTokenValid(decodedToken)) {
          const renewedResponse = await fetch(
            `${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}/api/auth/renew-token`
          );
          const renewedToken = await renewedResponse.json();
          if (!!renewedToken) {
            token = renewedToken.accessToken;
          }
        }
      }
      if (!!token) {
        return {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      }
      return {};
    },
    webSocketImpl: WebSocket,
  });

  return [
    dedupExchange,
    cacheExchange,
    ssr,
    authExchange({
      addAuthToOperation: ({ authState, operation }) => {
        // the token isn't in the auth state, return the operation without changes
        if (!authState || !authState.token) {
          return operation;
        }
        // fetchOptions can be a function (See Client API) but you can simplify this based on usage
        const fetchOptions: any =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};
        return {
          ...operation,
          context: {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
              },
            },
          },
        };
      },
      willAuthError: ({ authState }: WillAuthErrorInput) => {
        if (!authState || !authState.token) return true;
        // e.g. check for expiration, existence of auth etc
        const decodedToken = authState.decodedToken;

        // const decodedToken = decodeJwt(token);
        const castedDecodedToken = decodedToken as { [key: string]: any };
        const expirationSeconds = castedDecodedToken['exp'];
        const currentSeconds = new Date().getTime() / 1000;
        const secondsTilExpire = expirationSeconds - currentSeconds;
        // refresh token if less than 15 mins left
        if (secondsTilExpire <= 900) return true;

        // been staring at this, seems like this should be false to me
        return false;
      },
      getAuth: async ({ authState }: IGetAuth) => {
        if (!authState) {
          let session: any = undefined;
          let token: string | undefined;
          let refreshToken: string | undefined;
          if (typeof window === 'undefined') {
            //server side
            if (!!ctx) {
              const { req, res } = ctx;
              if (!!req && !!res) {
                const { getSession } = await import('@auth0/nextjs-auth0');
                session = await getSession(req as any, res as any);
                if (!!session) {
                  token = session.accessToken;
                  refreshToken = session.refreshToken;
                }
              }
            }
          } else {
            //client side
            const sessionResponse = await fetch(
              `${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}/api/auth/fetch-session`
            );
            if (sessionResponse?.ok) {
              session = await sessionResponse.json();
            }
            if (
              !!session &&
              (!session.errorCode || session.errorCode !== 'NOT_AUTHORIZED')
            ) {
              token = session.accessToken;
              refreshToken = session.refreshToken;
            }
          }

          if (!!token && !!refreshToken) {
            const { decodeJwt } = await import('jose');

            const decodedToken = decodeJwt(token);

            if (isTokenValid(decodedToken)) {
              return { token, refreshToken, decodedToken };
            }

            return null;
          }

          return null;
        } else {
          if (!!authState.token && !!authState.decodedToken) {
            // Renew access token if necessary
            if (!isTokenValid(authState.decodedToken)) {
              const renewedResponse = await fetch(
                `${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}/api/auth/renew-token`
              );
              const renewedToken = await renewedResponse.json();
              if (!!renewedToken) {
                const { decodeJwt } = await import('jose');

                const decodedToken = decodeJwt(renewedToken.accessToken);

                return {
                  token: renewedToken.accessToken,
                  refreshToken: authState.refreshToken,
                  decodedToken,
                };
              }
            } else {
              return authState;
            }
          }
        }

        return null;
      },
    }),
    multipartFetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    }),
  ];
};
