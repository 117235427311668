import { gql } from 'urql';

export const SERVER_QUERY_SUGGESTED_ADS = gql`
  query suggestedAdsV2($userId: bigint!) {
    suggestedAdsV2(userId: $userId) {
      acceptedAdventurerCount
      adventuringPlatformName
      cost
      created
      dowFriday
      dowMonday
      dowSaturday
      dowSunday
      dowThursday
      dowTuesday
      dowWednesday
      frequency
      gameTypeName
      id
      image
      matchScore
      name
      openAdventurerCount
      quickMatch
      quickMatchDate
      requestedAdventurerCount
    }
  }
`;
