import React from 'react';
import { ButtonProps, SxProps, Typography } from '@mui/material';
import { StyledMenuButton } from './MenuButton.styles';
import Link from 'next/link';
import { Url } from 'next/dist/shared/lib/router/router';

interface MenuButtonProps extends ButtonProps {
  link?: boolean;
  linkUrl?: Url;
  linkAs?: string;
  buttonStyle?: SxProps;
  fullWidth?: boolean;
}

export const MenuButton = ({
  children,
  link,
  linkUrl,
  linkAs,
  buttonStyle,
  fullWidth,
  ...props
}: MenuButtonProps) => {
  const externalRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

  if (!!link && !!linkUrl) {
    if (typeof linkUrl === 'string' && externalRegex.test(linkUrl)) {
      return (
        <a
          href={linkUrl.toString()}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            display: 'inline-flex',
            textDecoration: 'none',
            width: !!fullWidth ? '100%' : 'unset',
          }}
        >
          <StyledMenuButton {...props} fullWidth={fullWidth}>
            <Typography
              variant='h3'
              noWrap
              textTransform='none'
              textOverflow='unset'
              overflow='unset'
              sx={buttonStyle}
            >
              {children}
            </Typography>
          </StyledMenuButton>
        </a>
      );
    } else {
      return (
        <Link
          href={linkUrl}
          as={linkAs}
          style={{
            display: 'inline-flex',
            textDecoration: 'none',
            width: !!fullWidth ? '100%' : 'unset',
          }}
        >
          <StyledMenuButton {...props} fullWidth={fullWidth}>
            <Typography
              variant='h3'
              noWrap
              textTransform='none'
              textOverflow='unset'
              overflow='unset'
              sx={buttonStyle}
            >
              {children}
            </Typography>
          </StyledMenuButton>
        </Link>
      );
    }
  }

  return (
    <StyledMenuButton {...props}>
      <Typography
        variant='h3'
        noWrap
        textTransform='none'
        textOverflow='unset'
        overflow='unset'
        sx={buttonStyle}
      >
        {children}
      </Typography>
    </StyledMenuButton>
  );
};
