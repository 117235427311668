import { OperationResult } from 'urql';
import { GenerateTokenDocument } from '@components/cart/CartSidebarView/generate-token.query.generated';

export const generateToken = async (client: any): Promise<boolean> => {
  try {
    let generateTokenData: OperationResult = await client
      .query(
        GenerateTokenDocument,
        {},
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise();

    if (
      !!generateTokenData.error ||
      !generateTokenData.data.generateToken.success
    ) {
      return false;
    }

    let email =
      generateTokenData.data.generateToken.result?.email?.toLowerCase();
    let token = generateTokenData.data.generateToken.result?.password_token;

    if (!token) {
      generateTokenData = await client
        .query(
          GenerateTokenDocument,
          {},
          {
            requestPolicy: 'network-only',
          }
        )
        .toPromise();

      email = generateTokenData.data.generateToken.result?.email?.toLowerCase();
      token = generateTokenData.data.generateToken.result?.password_token;
    }

    const swell = await import('swell-js');

    await swell.account.login(email?.toLowerCase(), {
      password_token: token,
    });

    await swell.cart.update({
      account: {
        email: email?.toLowerCase(),
      },
    });

    return true;
  } catch (e) {
    console.log('generate token error', e);
  }
  return false;
};
