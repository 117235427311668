import * as Types from '../../graphql/types';

import { gql } from 'urql';
export type NotificationNotificationHistoryFieldsFragment = { __typename?: 'demiplane_notification_history', id: string, heading: string, content: string, url: string, icon: string, created: string, read: boolean, adventure_id?: string | null, user_id: string, user: { __typename?: 'demiplane_user', user_adventures: Array<{ __typename?: 'demiplane_user_adventure', id: string, user_id: string, adventure_id: string }> } };

export const NotificationNotificationHistoryFieldsFragmentDoc = gql`
    fragment NotificationNotificationHistoryFields on demiplane_notification_history {
  id
  heading
  content
  url
  icon
  created
  read
  adventure_id
  user_id
  user {
    user_adventures {
      id
      user_id
      adventure_id
    }
  }
}
    `;