import { styled } from '@mui/system';

const Bag = ({ ...props }) => {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 18 19'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.00003 18.5C4.75784 18.5 3.75003 17.4922 3.75003 16.25C3.75003 15.0078 4.75784 14 6.00003 14C7.24222 14 8.25003 15.0078 8.25003 16.25C8.25003 17.4922 7.24222 18.5 6.00003 18.5ZM6.00003 17C6.41441 17 6.75003 16.6644 6.75003 16.25C6.75003 15.8356 6.41441 15.5 6.00003 15.5C5.58564 15.5 5.25003 15.8356 5.25003 16.25C5.25003 16.6644 5.58564 17 6.00003 17Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 18.5C13.7578 18.5 12.75 17.4922 12.75 16.25C12.75 15.0078 13.7578 14 15 14C16.2422 14 17.25 15.0078 17.25 16.25C17.25 17.4922 16.2422 18.5 15 18.5ZM15 17C15.4144 17 15.75 16.6644 15.75 16.25C15.75 15.8356 15.4144 15.5 15 15.5C14.5856 15.5 14.25 15.8356 14.25 16.25C14.25 16.6644 14.5856 17 15 17Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.10099 5.74998L5.80599 11.0974C5.85568 11.4734 6.17724 11.7527 6.56254 11.7499H14.5041C14.8679 11.7574 15.1838 11.5024 15.2551 11.1453L16.3351 5.75005L5.10099 5.74998ZM4.90224 4.24998H17.25C17.7234 4.24998 18.0787 4.68311 17.985 5.14717L16.7259 11.4388C16.5131 12.5075 15.5653 13.2706 14.49 13.25H6.56904C5.43185 13.2594 4.46623 12.4194 4.31904 11.2935L3.17904 2.65251C3.13029 2.27938 2.81249 2.00096 2.43749 2H0.75C0.335616 2 0 1.66438 0 1.25C0 0.835616 0.335616 0.5 0.75 0.5H2.43845C3.56719 0.501875 4.5197 1.33906 4.66594 2.45655L4.90224 4.24998Z'
      />
    </svg>
  );
};

export default styled(Bag)(({}) => ({}));
