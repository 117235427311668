import { gql } from 'urql';

export const SERVER_MUTATION_UPDATE_ADVENTURE_MATCH_MAKING_STATUS = gql`
  mutation UpdateAdventureMatchMakingStatus(
    $adventureId: String!
    $matchMakingStatusPartyMgmt: Boolean
    $matchMakingStatusAdvType: Boolean
    $matchMakingStatusAdvDetails: Boolean
    $matchMakingStatusThemeScores: Boolean
    $matchMakingStatusAdvImage: Boolean
    $matchMakingStatusGameAvail: Boolean
  ) {
    updateAdventureMatchMakingStatus(
      adventureId: $adventureId
      matchMakingStatusPartyMgmt: $matchMakingStatusPartyMgmt
      matchMakingStatusAdvType: $matchMakingStatusAdvType
      matchMakingStatusAdvDetails: $matchMakingStatusAdvDetails
      matchMakingStatusThemeScores: $matchMakingStatusThemeScores
      matchMakingStatusAdvImage: $matchMakingStatusAdvImage
      matchMakingStatusGameAvail: $matchMakingStatusGameAvail
    ) {
      __typename
      id
    }
  }
`;
