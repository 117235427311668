import { gql } from 'urql';

export const SERVER_MUTATION_ACCEPT_RECRUIT = gql`
  mutation AcceptRecruit($connectionId: String!) {
    acceptRecruit(connectionId: $connectionId) {
      __typename
      id
    }
  }
`;
