import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledMenuButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,

  '&:hover': {
    backgroundColor: 'transparent',
  },
  padding: 0,
  m: 0,
  height: 52,
}));
