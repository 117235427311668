import { gql } from 'urql';

export const SERVER_MUTATION_SPECIAL_REQUEST = gql`
  mutation SpecialRequest($adventureId: String!) {
    specialRequest(adventureId: $adventureId) {
      __typename
      id
    }
  }
`;
