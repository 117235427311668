import React, { useEffect, useState } from 'react';
import { Avatar, AvatarClassKey } from '@mui/material';
interface IUserAvatarProps {
  id: string;
  name: string;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  backgroundColor: string;
  displayPointer?: boolean;
  height?: string;
  width?: string;
  src?: string;
  classes?: Partial<Record<AvatarClassKey, string>> | undefined;
}

export const UserAvatar = ({
  id,
  name,
  handleClick,
  backgroundColor,
  displayPointer,
  height,
  width,
  src,
  classes,
}: IUserAvatarProps) => {
  const [displayFallback, setDisplayFallback] = useState(false);

  let useHeight = '2.5rem';
  let useWidth = '2.5rem';

  if (!!height) {
    useHeight = height;
  }
  if (!!width) {
    useWidth = width;
  }

  useEffect(() => {
    if (!!src) {
      setDisplayFallback(false);
    } else {
      setDisplayFallback(true);
    }
  }, [src]);

  return (
    <Avatar
      src={src}
      key={id}
      alt={`${name}`}
      sx={
        !!displayPointer
          ? {
              backgroundColor: !!displayFallback ? backgroundColor : '#ffffff',
              cursor: 'pointer',
              height: useHeight,
              width: useWidth,

              '& > img': {
                margin: 0,
              },
            }
          : {
              backgroundColor: !!displayFallback ? backgroundColor : '#ffffff',
              height: useHeight,
              width: useWidth,
              '& > img': {
                margin: 0,
              },
            }
      }
      onClick={(e: any) => handleClick(e)}
      classes={!!classes ? classes : {}}
      data-cy='user-avatar'
    >
      {!!displayFallback && name.substring(0, 1).toUpperCase()}
    </Avatar>
  );
};
