import { alpha, styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledMemberButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(90deg, #00ABFF 0%, #0D49FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25))`,
  color: theme.palette.common.white,
  height: 38,
  borderWidth: `1px !important`,
  borderStyle: 'solid !important',
  borderColor: `${alpha(theme.palette.common.white, 0.4)} !important`,

  '&:hover': {
    background: `linear-gradient(90deg, #00ABFF 0%, #0D49FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25))`,
  },
  marginLeft: 8,
  marginRight: 8,
}));
