import * as Types from '../../graphql/types';

import { gql } from 'urql';
import { NotificationNotificationHistoryFieldsFragmentDoc } from './notification-notification-history.fragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NotificationSubscriptionVariables = Types.Exact<{
  currentUserId: Types.Scalars['bigint']['input'];
  limit: Types.Scalars['Int']['input'];
}>;


export type NotificationSubscription = { __typename?: 'subscription_root', demiplane_notification_history: Array<{ __typename?: 'demiplane_notification_history', id: string, heading: string, content: string, url: string, icon: string, created: string, read: boolean, adventure_id?: string | null, user_id: string, user: { __typename?: 'demiplane_user', user_adventures: Array<{ __typename?: 'demiplane_user_adventure', id: string, user_id: string, adventure_id: string }> } }> };


export const NotificationDocument = gql`
    subscription notification($currentUserId: bigint!, $limit: Int!) {
  demiplane_notification_history(
    where: {user: {id: {_eq: $currentUserId}}}
    limit: $limit
    order_by: {created: desc}
  ) {
    ...NotificationNotificationHistoryFields
  }
}
    ${NotificationNotificationHistoryFieldsFragmentDoc}`;

export function useNotificationSubscription<TData = NotificationSubscription>(options: Omit<Urql.UseSubscriptionArgs<NotificationSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<NotificationSubscription, TData>) {
  return Urql.useSubscription<NotificationSubscription, TData, NotificationSubscriptionVariables>({ query: NotificationDocument, ...options }, handler);
};