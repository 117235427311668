import type { SWRHook } from '@commerce/utils/types';
import useCustomer, { type UseCustomer } from '@commerce/customer/use-customer';
import type { CustomerHook } from '@commerce/types/customer';

export default useCustomer as UseCustomer<typeof handler>;

export const handler: SWRHook<CustomerHook> = {
  fetchOptions: {
    url: '/api/bigcommerce/customer',
    method: 'GET',
  },
  async fetcher({ options, fetch }) {
    const data = await fetch(options);
    return data?.customer ?? null;
  },
  useHook:
    ({ useData }) =>
    (input) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useData({
        swrOptions: {
          revalidateOnFocus: false,
          ...input?.swrOptions,
        },
      });
    },
};
