import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { NotificationsIconButton } from '../NotificationsIconButton';
import { CartIconButton } from '../CartIconButton';
import { TopNavAvatar } from '../TopNavAvatar';
import { Search } from '../Search';
import { ChatProvider } from 'src/state/providers/ChatProvider';
import { DemiplaneAuthUser } from 'src/lib/serverSidePropsAuthCheck';
import { useSetRecoilState } from 'recoil';
import { unreadNotifications as unreadNotificationsAtom } from 'Atoms/notifications';
import { useNotificationSubscription } from 'Components/NotificationDrawer/notification.subscription.generated';
import { NotificationNotificationHistoryFieldsFragment } from 'Components/NotificationDrawer/notification-notification-history.fragment.generated';
import { NotificationProvider } from 'src/state/providers/NotificationProvider';

interface UserButtonProps {
  currentUser?: DemiplaneAuthUser | null;
  isCommerceEnabled: boolean;
  showSearch: boolean;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserButtonsBase = ({
  currentUser,
  isCommerceEnabled,
  showSearch,
  setShowSearch,
}: UserButtonProps) => {
  const setUnreadNotifications = useSetRecoilState(unreadNotificationsAtom);

  const [notificationHistoryResult] = useNotificationSubscription({
    variables: {
      currentUserId: !!currentUser ? currentUser.id : '',
      limit: 25,
    },
    pause: !currentUser,
  });
  const { data: notificationHistoryData } = notificationHistoryResult;

  const unreadNotifications: NotificationNotificationHistoryFieldsFragment[] =
    notificationHistoryData?.demiplane_notification_history
      .filter((n) => !n.read)
      .filter((n) => {
        if (!!n.adventure_id) {
          let found = false;
          n.user.user_adventures.forEach((uad: any) => {
            if (n.adventure_id === uad.adventure_id && !found) {
              found = true;
            }
          });
          return found;
        } else {
          return true;
        }
      }) ?? [];

  const readNotifications: NotificationNotificationHistoryFieldsFragment[] =
    notificationHistoryData?.demiplane_notification_history.filter(
      (n) => n.read
    ) ?? [];

  useEffect(() => {
    setUnreadNotifications(unreadNotifications.length > 0);
  }, [setUnreadNotifications, unreadNotifications.length]);

  return (
    <Box display='flex' alignItems='center'>
      <Search showSearch={showSearch} setShowSearch={setShowSearch} />

      <CartIconButton
        currentUser={currentUser}
        isCommerceEnabled={isCommerceEnabled}
      />
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        {!!currentUser && (
          <NotificationsIconButton
            hasUnread={unreadNotifications.length > 0}
            unreadNotifications={unreadNotifications}
            readNotifications={readNotifications}
          />
        )}
      </Box>
      <TopNavAvatar currentUserGssp={currentUser} />
    </Box>
  );
};

export const UserButtons = (props: UserButtonProps) => (
  <NotificationProvider>
    <ChatProvider>
      <UserButtonsBase {...props} />
    </ChatProvider>
  </NotificationProvider>
);
