export const isTokenValid = (decodedToken: { [key: string]: any }): boolean => {
  let isValid = false;

  const expirationSeconds = decodedToken['exp'];
  const currentSeconds = new Date().getTime() / 1000;
  const secondsTilExpire = expirationSeconds - currentSeconds;

  if (secondsTilExpire > 900) {
    isValid = true;
  }

  return isValid;
};
