import React from 'react';

interface BellIconProps {
  height: any;
  width: any;
}

export const BellIcon = ({ height, width }: BellIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.53846 16.6038H8.07692C8.07692 17.6459 8.93781 18.4906 10 18.4906C11.0622 18.4906 11.9231 17.6459 11.9231 16.6038H13.4615C13.4615 18.4788 11.9111 20 10 20C8.08892 20 6.53846 18.4788 6.53846 16.6038ZM20 14.717V15.0943C20 15.9316 19.3119 16.6038 18.4615 16.6038H1.53846C0.689615 16.6038 0 15.9286 0 15.0943V14.717C0 13.3874 1.00362 12.2877 2.30769 12.102V7.54721C2.30769 3.377 5.75119 0 10 0C14.2488 0 17.6923 3.37855 17.6923 7.54721V12.102C18.9994 12.2862 20 13.3858 20 14.717ZM18.4615 14.717C18.4615 14.0905 17.9462 13.5849 17.3047 13.5849C16.6692 13.5849 16.1538 13.0763 16.1538 12.4557V7.54702C16.1538 4.2127 13.3999 1.50925 10 1.50925C6.60008 1.50925 3.84615 4.21123 3.84615 7.54702V12.4557C3.84615 13.0792 3.32931 13.5849 2.69531 13.5849C2.05677 13.5849 1.53846 14.092 1.53846 14.717V15.0943H18.4615V14.717Z'
        fill='#FDFDFD'
      />
    </svg>
  );
};
