import { gql } from 'urql';

export const SERVER_QUERY_ALMOST_FULL_ADS = gql`
  query almostFullAdsV2($userId: bigint!) {
    almostFullAdsV2(userId: $userId) {
      id
      name
      matchScore
      acceptedAdventurerCount
      openAdventurerCount
      requestedAdventurerCount
      gameTypeName
      adventuringPlatformName
      frequency
      dowSunday
      dowMonday
      dowTuesday
      dowWednesday
      dowThursday
      dowFriday
      dowSaturday
      quickMatch
      quickMatchDate
      cost
      image
    }
  }
`;
