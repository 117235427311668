import React, { useEffect } from 'react';
import { ClickAwayListener, useMediaQuery } from '@mui/material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { GlobalSearchBar } from 'Components/GlobalSearchBar';
import SearchIcon from '@mui/icons-material/Search';

interface SearchProps {
  showSearch: boolean;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Search = ({ showSearch, setShowSearch }: SearchProps) => {
  const matchesWidth = useMediaQuery('(min-width:1366px)');

  useEffect(() => {
    const handleResize = () => {
      if (!!matchesWidth) {
        setShowSearch(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  return (
    <ClickAwayListener
      onClickAway={(event: any) => {
        if (!matchesWidth && event.target.nodeName !== 'BODY' && !!showSearch) {
          setShowSearch(!showSearch);
        }
      }}
    >
      <Box>
        <Box
          display={{ xs: showSearch ? 'block' : 'none', lg: 'block' }}
          mx={0.5}
        >
          {(!!showSearch || !!matchesWidth) && (
            <GlobalSearchBar
              inHeader={true}
              onSubmit={() => setShowSearch(false)}
            />
          )}
        </Box>
        <IconButton
          sx={{
            fill: '#fff',
            display: { xs: showSearch ? 'none' : 'block', lg: 'none' },
            mx: { xs: 0, sm: 0.5 },
            pl: { xs: 0, sm: 1.5 },
          }}
          aria-label='Search'
          size='large'
          data-cy='top-nav-search-btn'
          onClick={handleSearchIconClick}
        >
          <SearchIcon
            sx={{
              width: { xs: 20, sm: 24 },
              height: { xs: 20, sm: 24 },
            }}
          />
        </IconButton>
      </Box>
    </ClickAwayListener>
  );
};
