import { atom } from 'recoil';

export const notificationCenterOpen = atom<boolean>({
  key: 'notificationCenterOpen',
  default: false,
});

export const unreadNotifications = atom<boolean>({
  key: 'unreadNotifications',
  default: false,
});
