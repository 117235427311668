import { gql } from 'urql';

export const SERVER_QUERY_SLS_GAME_TYPES = gql`
  query slsGameTypes {
    slsGameTypes {
      success
      message
      data
      error
    }
  }
`;
