import { gql } from 'urql';

export const SERVER_QUERY_SLS_CHAT_TOKEN = gql`
  query slsChatToken {
    slsChatToken {
      success
      message
      data
      error
    }
  }
`;
