import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SlsSubmitGiphyMutationVariables = Types.Exact<{
  chatHash: Types.Scalars['String']['input'];
  url: Types.Scalars['String']['input'];
}>;


export type SlsSubmitGiphyMutation = { __typename?: 'mutation_root', slsSubmitGiphy?: { __typename?: 'BaseResponse', data?: { [key: string]: any } | null, error?: string | null, message?: string | null, success: boolean } | null };


export const SlsSubmitGiphyDocument = gql`
    mutation slsSubmitGiphy($chatHash: String!, $url: String!) {
  slsSubmitGiphy(chatHash: $chatHash, url: $url) {
    data
    error
    message
    success
  }
}
    `;

export function useSlsSubmitGiphyMutation() {
  return Urql.useMutation<SlsSubmitGiphyMutation, SlsSubmitGiphyMutationVariables>(SlsSubmitGiphyDocument);
};