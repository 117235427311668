import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SlsSubmitSystemMessageMutationVariables = Types.Exact<{
  chatHash: Types.Scalars['String']['input'];
  message: Types.Scalars['String']['input'];
}>;


export type SlsSubmitSystemMessageMutation = { __typename?: 'mutation_root', slsSubmitSystemMessage?: { __typename?: 'BaseResponse', data?: { [key: string]: any } | null, error?: string | null, message?: string | null, success: boolean } | null };


export const SlsSubmitSystemMessageDocument = gql`
    mutation slsSubmitSystemMessage($chatHash: String!, $message: String!) {
  slsSubmitSystemMessage(chatHash: $chatHash, message: $message) {
    data
    error
    message
    success
  }
}
    `;

export function useSlsSubmitSystemMessageMutation() {
  return Urql.useMutation<SlsSubmitSystemMessageMutation, SlsSubmitSystemMessageMutationVariables>(SlsSubmitSystemMessageDocument);
};