import { useMemo } from 'react';
import { SWRHook } from '@commerce/utils/types';
import useCart, { UseCart } from '@commerce/cart/use-cart';
import type { GetCartHook } from '@commerce/types/cart';

export default useCart as UseCart<typeof handler>;

export const handler: SWRHook<GetCartHook> = {
  fetchOptions: {
    url: '/api/bigcommerce/cart',
    method: 'GET',
  },
  fetcher: async ({ options, input: {}, fetch }) => {
    return await fetch(options);
  },
  useHook:
    ({ useData }) =>
    (input) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = useData({
        swrOptions: { revalidateOnFocus: false, ...input?.swrOptions },
      });

      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useMemo(
        () =>
          Object.create(response, {
            isEmpty: {
              get() {
                return (response.data?.lineItems.length ?? 0) <= 0;
              },
              enumerable: true,
            },
          }),
        [response]
      );
    },
};
