import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SlsConversationParticipantsQueryVariables = Types.Exact<{
  chatHash: Types.Scalars['String']['input'];
}>;


export type SlsConversationParticipantsQuery = { __typename?: 'query_root', slsConversationParticipants?: { __typename?: 'BaseResponse', data?: { [key: string]: any } | null, message?: string | null, error?: string | null, success: boolean } | null };


export const SlsConversationParticipantsDocument = gql`
    query slsConversationParticipants($chatHash: String!) {
  slsConversationParticipants(chatHash: $chatHash) {
    data
    message
    error
    success
  }
}
    `;

export function useSlsConversationParticipantsQuery(options: Omit<Urql.UseQueryArgs<SlsConversationParticipantsQueryVariables>, 'query'>) {
  return Urql.useQuery<SlsConversationParticipantsQuery, SlsConversationParticipantsQueryVariables>({ query: SlsConversationParticipantsDocument, ...options });
};