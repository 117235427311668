import { styled } from '@mui/material/styles';

export const StyledList = styled('li')(() => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'end',
}));

export const StyledSpan = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '1.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '9999px',
  borderWidth: '1px',
  borderStyle: 'solid',
  backgroundColor: theme.palette.demi.blue,
  fontSize: '.75rem',
  lineHeight: '1rem',
  fontWeight: 700,
  paddingLeft: '2.5px',
  paddingRight: '2.5px',
  minWidth: '1.25rem',
  minHeight: '1.25rem',
  borderColor: '#202020',
  color: '#fff',
}));
