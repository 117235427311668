import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SlsReadNotificationMutationVariables = Types.Exact<{
  notificationId: Types.Scalars['bigint']['input'];
}>;


export type SlsReadNotificationMutation = { __typename?: 'mutation_root', slsReadNotification?: { __typename?: 'BaseResponse', data?: { [key: string]: any } | null, error?: string | null, message?: string | null, success: boolean } | null };


export const SlsReadNotificationDocument = gql`
    mutation slsReadNotification($notificationId: bigint!) {
  slsReadNotification(notificationId: $notificationId) {
    data
    error
    message
    success
  }
}
    `;

export function useSlsReadNotificationMutation() {
  return Urql.useMutation<SlsReadNotificationMutation, SlsReadNotificationMutationVariables>(SlsReadNotificationDocument);
};