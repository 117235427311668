import React, { useCallback, useMemo, useState } from 'react';
import { RecoilEnv, RecoilRoot } from 'recoil';
import { RecoilRootContext } from '../contexts/RecoilRootContext';

interface IRecoilRootProviderProps {
  children: any;
}

export const RecoilRootProvider = React.memo(
  ({ ...props }: IRecoilRootProviderProps) => {
    const [dateString, setDateString] = useState<number>();

    RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

    const resetRecoil = useCallback(() => {
      setDateString(new Date().getTime());
    }, []);

    // const DebugObserver = () => {
    //   const snapshot = useRecoilSnapshot();
    //   useEffect(() => {
    //     console.log('The following atoms were modified:');
    //     for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
    //       console.log(node.key, snapshot.getLoadable(node).getValue());
    //     }
    //   }, [snapshot]);

    //   return null;
    // };

    const returnObj = useMemo(() => {
      return {
        resetRecoil,
      };
    }, [resetRecoil]);

    return (
      <RecoilRootContext.Provider value={returnObj}>
        <RecoilRoot key={dateString}>
          {/* {!!process.env.NEXT_PUBLIC_DEBUG_RECOIL &&
            process.env.NEXT_PUBLIC_DEBUG_RECOIL === 'true' && (
              <DebugObserver />
            )} */}
          {props.children}
        </RecoilRoot>
      </RecoilRootContext.Provider>
    );
  }
);

RecoilRootProvider.displayName = 'RecoilRootProvider';
