import { gql } from 'urql';

export const SERVER_MUTATION_DECLINE_INVITE = gql`
  mutation DeclineInvite($connectionId: String!) {
    declineInvite(connectionId: $connectionId) {
      __typename
      id
    }
  }
`;
