import React, { useCallback, useMemo } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import { useSlsReadNotificationMutation } from 'Graphql/sls-read-notification.mutation.generated';
import { useSlsReadNotificationByUrlMutation } from 'Graphql/sls-read-notification-by-url.mutation.generated';
import { useSlsReadUnreadNotificationsMutation } from 'Graphql/sls-read-unread-notifications.mutation.generated';

export const NotificationProvider = React.memo(({ ...props }) => {
  const [, readUnreadNotificationsMutation] =
    useSlsReadUnreadNotificationsMutation();
  const [, readNotificationMutation] = useSlsReadNotificationMutation();
  const [, readNotificationByUrlMutation] =
    useSlsReadNotificationByUrlMutation();

  const readUnreadNotifications = useCallback(async () => {
    const result = await readUnreadNotificationsMutation({});

    if (!result.data?.slsReadUnreadNotifications?.success) {
      console.log('readUnreadNotifications Error');
      return false;
    }
    return true;
  }, [readUnreadNotificationsMutation]);

  const readNotification = useCallback(
    async (notificationId: string) => {
      const result = await readNotificationMutation({
        notificationId,
      });

      if (!result.data?.slsReadNotification?.success) {
        console.log('readNotification Error');
      }
      return true;
    },
    [readNotificationMutation]
  );

  const readNotificationByUrl = useCallback(
    async (url: string) => {
      const result = await readNotificationByUrlMutation({
        url,
      });

      if (!!result.error) {
        console.log(result.error);
        return false;
      }

      return true;
    },
    [readNotificationByUrlMutation]
  );

  const returnObj = useMemo(() => {
    return {
      readUnreadNotifications,
      readNotification,
      readNotificationByUrl,
    };
  }, [readNotification, readNotificationByUrl, readUnreadNotifications]);

  return <NotificationContext.Provider value={returnObj} {...props} />;
});

NotificationProvider.displayName = 'NotificationProvider';
