import React from 'react';
import { ButtonProps, Typography } from '@mui/material';
import { StyledMemberButton } from './MenuMemberButton.styles';
import Link from 'next/link';

interface MenuMemberButtonProps extends ButtonProps {
  link?: boolean;
  linkUrl?: string;
}

export const MenuMemberButton = ({
  children,
  link,
  linkUrl,
  ...props
}: MenuMemberButtonProps) => {
  if (!!link && !!linkUrl) {
    return (
      <Link
        href={linkUrl}
        role='menuitem'
        style={{
          display: 'inline-flex',
          marginRight: 8,
          marginLeft: 8,
          textDecoration: 'none',
        }}
      >
        <StyledMemberButton {...props}>
          <Typography variant='h2' fontWeight={600} noWrap textTransform='none'>
            {children}
          </Typography>
        </StyledMemberButton>
      </Link>
    );
  }

  return (
    <StyledMemberButton {...props}>
      <Typography variant='h2' fontWeight={600} noWrap textTransform='none'>
        {children}
      </Typography>
    </StyledMemberButton>
  );
};
