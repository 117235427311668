import { gql } from 'urql';

export const SERVER_QUERY_SLS_USER_CONVERSATIONS = gql`
  query slsUserConversations {
    slsUserConversations {
      success
      message
      data
      error
    }
  }
`;
