import { useUser } from '@auth0/nextjs-auth0/client';
import { DemiplaneAuthUser } from 'src/lib/serverSidePropsAuthCheck';
import { OperationResult, gql, useClient } from 'urql';
import { useCallback, useEffect, useState } from 'react';

const userBySubQuery = gql`
  query userBySub($sub: String!) {
    demiplane_user(where: { auth0_user_id: { _eq: $sub } }) {
      id
      cognito_id
      auth0_user_id
      username
      email
      first_name
      last_name
      address1
      address2
      city
      region {
        code2
      }
      country {
        code2
        name
      }
      zipcode
      experience
      combat
      puzzles_logic
      social_intrigue
      player_story
      role_playing
      strict_rules
      first_login
      stripe_customer_id
      stripe_connect_id
      access_level
      feature_level
      marketing_opt_in
      upload {
        id
        file
      }
      user_metadatum {
        accepted_terms
      }
      pronoun {
        id
      }
    }
  }
`;

export const useCurrentUser: () => {
  currentUser?: DemiplaneAuthUser;
  error?: Error;
  isLoading: boolean;
  checkSession: () => Promise<void>;
  fetchDatabaseUser: (sub: string) => Promise<DemiplaneAuthUser | undefined>;
} = () => {
  const client = useClient();
  const {
    user: auth0User,
    isLoading,
    error,
    checkSession: auth0CheckSession,
  } = useUser();
  const [currentUser, setCurrentUser] = useState<DemiplaneAuthUser>();

  const fetchDatabaseUser = useCallback(
    async (sub: string) => {
      const result: OperationResult = await client
        .query(
          userBySubQuery,
          {
            sub,
          },
          { requestPolicy: 'cache-and-network' }
        )
        .toPromise();

      if (!!result?.error) {
        console.log('fetchDatabaseUser Error:', result.error);
      }

      const databaseUser = result?.data?.demiplane_user[0];
      return databaseUser as DemiplaneAuthUser | undefined;
    },
    [client]
  );

  const checkSession = useCallback(async () => {
    await fetch(`/api/auth/renew-token`);
    auth0CheckSession();

    if (!!auth0User) {
      const newUser = await fetchDatabaseUser(auth0User.sub ?? '');
      setCurrentUser(newUser as DemiplaneAuthUser);
    } else {
      setCurrentUser(undefined);
    }
  }, [auth0CheckSession, auth0User, fetchDatabaseUser]);

  useEffect(() => {
    const asyncFetchDatabaseUser = async (user: any) => {
      const newUser = await fetchDatabaseUser(user.sub ?? '');
      setCurrentUser(newUser as DemiplaneAuthUser);
    };

    if (!currentUser) {
      if (!isLoading && !error && !!auth0User) {
        if (!auth0User[`https://demiplane.com/user`]) {
          asyncFetchDatabaseUser(auth0User);
        } else {
          const _user: any = auth0User[`https://demiplane.com/user`];
          _user.auth0_user_id = auth0User.sub;
          setCurrentUser(_user as DemiplaneAuthUser);
        }
      }
    }
  }, [auth0User, currentUser, error, fetchDatabaseUser, isLoading]);

  return {
    currentUser,
    isLoading,
    error,
    checkSession,
    fetchDatabaseUser,
  };
};
