import { gql } from 'urql';

export const SERVER_MUTATION_ENABLE_ADVENTURE_MATCHMAKING = gql`
  mutation UpdateAdventureMatchmaking(
    $adventureId: String!
    $adventurerCount: Float!
    $autoJoin: Boolean!
    $threshold: Float!
  ) {
    enableAdventureMatchmaking(
      adventureId: $adventureId
      adventurerCount: $adventurerCount
      autoJoin: $autoJoin
      threshold: $threshold
    ) {
      __typename
      id
    }
  }
`;
