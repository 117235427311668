import { useCallback } from 'react';
import type { MutationHook } from '@commerce/utils/types';
import useLogout, { UseLogout } from '@commerce/auth/use-logout';
import type { LogoutHook } from '@commerce/types/logout';
import useCustomer from '../customer/use-customer';

export default useLogout as UseLogout<typeof handler>;

export const handler: MutationHook<LogoutHook> = {
  fetchOptions: {
    url: '/api/commerce/logout',
    method: 'GET',
  },
  useHook:
    ({ fetch }) =>
    () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { mutate } = useCustomer();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useCallback(
        async function logout() {
          const data = await fetch();
          await mutate(null, false);
          return data;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, mutate]
      );
    },
};
