import { gql } from 'urql';

export const SERVER_QUERY_SLS_ADVENTURING_PLATFORMS = gql`
  query slsAdventuringPlatforms {
    slsAdventuringPlatforms {
      success
      message
      data
      error
    }
  }
`;
