import * as Types from '../../graphql/types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActiveNexusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveNexusQuery = { __typename?: 'query_root', demiplane_nexus: Array<{ __typename?: 'demiplane_nexus', id: string, name: string, slug: string, is_publisher: boolean, asset_slug: string, config_data?: any | null, listing_data?: any | null, library_primer_data?: any | null }> };


export const ActiveNexusDocument = gql`
    query activeNexus {
  demiplane_nexus(
    where: {active: {_eq: true}, early_access: {_eq: false}, nexus_access_open: {_eq: true}}
    order_by: {name: asc}
  ) {
    id
    name
    slug
    is_publisher
    asset_slug
    config_data
    listing_data
    library_primer_data
  }
}
    `;

export function useActiveNexusQuery(options?: Omit<Urql.UseQueryArgs<ActiveNexusQueryVariables>, 'query'>) {
  return Urql.useQuery<ActiveNexusQuery, ActiveNexusQueryVariables>({ query: ActiveNexusDocument, ...options });
};