import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UnreadMessageCountByConversationQueryVariables = Types.Exact<{
  conversationId: Types.Scalars['String']['input'];
}>;


export type UnreadMessageCountByConversationQuery = { __typename?: 'query_root', unreadMessageCountByConversation?: { __typename?: 'UnreadMessageCountByConversationOutput', message: string, result?: number | null, success: boolean } | null };


export const UnreadMessageCountByConversationDocument = gql`
    query unreadMessageCountByConversation($conversationId: String!) {
  unreadMessageCountByConversation(conversationId: $conversationId) {
    message
    result
    success
  }
}
    `;

export function useUnreadMessageCountByConversationQuery(options: Omit<Urql.UseQueryArgs<UnreadMessageCountByConversationQueryVariables>, 'query'>) {
  return Urql.useQuery<UnreadMessageCountByConversationQuery, UnreadMessageCountByConversationQueryVariables>({ query: UnreadMessageCountByConversationDocument, ...options });
};