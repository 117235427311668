import { gql } from 'urql';

export const SERVER_MUTATION_READ_ADVENTURE_CONNECTIONS = gql`
  mutation readAdventureConnections(
    $adventureId: String!
    $adventureConnnectionIds: [String]!
  ) {
    readAdventureConnections(
      adventureId: $adventureId
      adventureConnnectionIds: $adventureConnnectionIds
    )
  }
`;
