import { gql } from 'urql';

export const SERVER_MUTATION_DECLINE_REQUEST = gql`
  mutation DeclineRequest($connectionId: String!) {
    declineRequest(connectionId: $connectionId) {
      __typename
      id
    }
  }
`;
