import { gql } from 'urql';

export const SERVER_MUTATION_SET_EPISODE_FREQUENCY = gql`
  mutation setEpisodeFrequency(
    $adventureId: String!
    $episodeFrequency: Float!
  ) {
    setEpisodeFrequency(
      adventureId: $adventureId
      episodeFrequency: $episodeFrequency
    )
  }
`;
